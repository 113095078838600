<template>
	<div>
		<NavBar style="max-height: 50px;margin-bottom: 10px;" :username="currentUserName"></NavBar>
    <v-app>
      <div style="background-color: #f5faff; height: 92vh !important;">
         <v-row cols="12">
        <v-col cols="2">
        <SideBar style="max-height: 50px;" ></SideBar>
        </v-col>
          <v-col cols="10">

             <h3 style="text-align:left; margin-left:65px; margin-top:30px;font-weight:800; font-size: 12pt;
                text-transform: uppercase;   letter-spacing: 1px;">Edit Doctor</h3>
            <v-card style="padding:10px; margin:20px 50px; height: 70vh; overflow: scroll; overflow-x: hidden;">
           <div style="margin: 20px 50px;">
             <div style="margin-bottom:20px">
               <V-avatar size="100">
                 <img
                          v-bind:src="doctor_profile_picture"
                          alt="Loading"
                        />
               </V-avatar>
             </div>
            <div v-if="selected_doctor" style='display: flex; flex-direction: column;'>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title"> Doctor Join Date: <span style="color:red">*</span> </span>
                </div>
                <div style="width: 50%" align="right">
                  <v-menu
                      ref="menu_obj"
                      v-model="menu_obj"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="doctorJoinDate"
                          label="Date of Joining"
                          background-color="#F4F6FA"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          style="border-radius:8px;"
                        ></v-text-field>
                      </template>
                      <v-date-picker style="padding:20px; height: 500px;color: black"
                        v-model="doctorJoinDate"
                        show-adjacent-months
                      ></v-date-picker>
                    </v-menu>
                </div>
              </div>

              <div style="display: flex; flex-direction: row; padding: 10px;">
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title"> Profile Picture:</span>
                </div>
                <div style="width: 50%" align="right">
                  <input type = "file" @change="getBase64" />
                </div>
              </div>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title"> Doctor Full Name in English: <span style="color:red">*</span> </span>
                </div>
                <div style="width: 50%" align="right">
                  <v-text-field
                    label="Enter Name in English"
                    solo
                    v-model="doctor_name"
                  ></v-text-field>
                </div>
              </div>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title"> Doctor Ful Name in Burmese:<span style="color:red">*</span></span>
                </div>
                <div style="width: 50%" align="right">
                  <v-text-field
                    label="Enter Name in Burmese"
                    solo
                    v-model="doctor_name_mm"
                  ></v-text-field>
                </div>
              </div>



              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title">  Gender:<span style="color:red">*</span></span>
                </div>
                <div style="width: 50%" align="right">
                  <v-select
                    label="Enter the gender"
                    :items = "gender"
                    solo
                    v-model="doctor_gender"
                  ></v-select>
                </div>
              </div>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title"> Date Of Birth: <span style="color:red">*</span> </span>
                </div>
                <div style="width: 50%" align="right">
                  <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="doctorDOB"
                          label="Date of Birth"
                          background-color="#F4F6FA"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          style="border-radius:8px;"
                        ></v-text-field>
                      </template>
                      <v-date-picker style="padding:20px; height: 500px;color: black"
                        v-model="doctorDOB"
                        show-adjacent-months
                      ></v-date-picker>
                    </v-menu>
                </div>
              </div>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title">  Contact Phone Number:<span style="color:red">*</span></span>
                </div>
                <div style="width: 50%" align="right">
                  <v-text-field
                    label="Enter Phone Number"
                    solo
                    type="number"
                    v-model="phone_number"
                    :rules="mobileNumberRules"
                  ></v-text-field>
                </div>
              </div>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title"> Alternate Phone Number:</span>
                </div>
                <div style="width: 50%" align="right">
                  <v-text-field
                    label="Enter Alternate Number"
                    solo
                    type="number"
                    v-model="doctorAltPhoneNumber"
                    :rules="altMobileNumberRules"
                  ></v-text-field>
                </div>
              </div>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title">  Email:<span style="color:red">*</span></span>
                </div>
                <div style="width: 50%" align="right">
                  <v-text-field
                    label="Enter Email"
                    solo
                    v-model="doctor_email"
                    :rules="emailRules"
                  ></v-text-field>
                </div>
              </div>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title">Residential State:<span style="color:red">*</span></span>
                </div>
                <div style="width: 50%" align="right">
                  <v-text-field
                    label="Residential State"
                    solo
                    v-model="residentialState"
                  ></v-text-field>
                </div>
              </div>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title"> SAMA No:<span style="color:red">*</span></span>
                </div>
                <div style="width: 50%" align="right">
                  <v-text-field
                    label="Enter Doctor SAMA ID"
                    solo
                    v-model="doctor_id"
                  ></v-text-field>
                </div>
              </div>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title"> Academic Title:<span style="color:red">*</span></span>
                </div>
                <div style="width: 50%" align="right">
                  <v-select
                    label="Select Title"
                    :items = "academicTitles"
                    solo
                    v-model="acadaemicTitle"
                    :rules = "titleRules"
                    ></v-select>
                  <!-- <v-text-field
                    label="e.g Medical Officer"
                    solo
                    v-model="acadaemicTitle"
                  ></v-text-field> -->
                </div>
              </div>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title">Experience Years:<span style="color:red">*</span></span>
                </div>
                <div style="width: 50%" align="right">
                  <v-text-field
                    label="Experience"
                    solo
                    type="number"
                    v-model="experience"
                    :rules="experienceRules"
                  ></v-text-field>
                </div>
              </div>

              <!-- <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title"> Service Years:<span style="color:red">*</span></span>
                </div>
                <div style="width: 50%" align="right">
                  <v-text-field
                    label="Service Year"
                    solo
                    type="number"
                    v-model="service_year"
                    :rules="serviceYearRules"
                  ></v-text-field>
                </div>
              </div> -->

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title">Relevant Medical Degree:<span style="color:red">*</span></span>
                </div>
                <div style="width: 50%" align="right">
                  <v-text-field
                    label="Enter degrees"
                    solo
                    v-model="degree"
                  ></v-text-field>
                </div>
              </div>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title"> Speciality:<span style="color:red">*</span></span>
                </div>
                <div style="width: 50%" align="right">
                  <v-select
                    label="Specialization"
                    solo
                    :items = "specialist_array"
                    item-text = "specialization_name"
                    item-value = "_id"
                    v-model="selected_specialization"
                    return-object
                  ></v-select>
                </div>
              </div>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title">Specialized subjects:</span>
                </div>
                <div style="width: 50%" align="right">
                  <v-text-field
                    label="Specialized subjects"
                    solo
                    v-model="specializedSubject"
                    :rules="specializedSubjectRules"
                  ></v-text-field>
                </div>
              </div>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title">Affiliated Hospital:</span>
                </div>
                <div style="width: 50%" align="right">
                  <v-text-field
                    label="Affiliated Hospital"
                    solo
                    v-model="affiliatedHospital"
                  ></v-text-field>
                </div>
              </div>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title">Commited Schedule:<span style="color:red">*</span></span>
                </div>
                <div style="width: 50%" align="right">
                  <v-text-field
                    label="Committed Schedule"
                    solo
                    v-model="committedSchedule"
                  ></v-text-field>
                </div>
              </div>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title">Consultation minutes:<span style="color:red">*</span></span>
                </div>
                <div style="width: 50%" align="right">
                  <v-text-field
                    label="Consultation minutes"
                    solo
                    v-model="consultationMinutes"
                  ></v-text-field>
                </div>
              </div>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title">Break time minutes before teleconsulting:<span style="color:red">*</span></span>
                </div>
                <div style="width: 50%" align="right">
                  <v-text-field
                    label="Break Time minutes"
                    solo
                    v-model="breakTimeMinutes"
                  ></v-text-field>
                </div>
              </div>


              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title"> Service Fee:<span style="color:red">*</span></span>
                </div>
                <div style="width: 50%" align="right">
                  <v-text-field
                    label="Service Fee"
                    solo
                    type="number"
                    v-model="service_fee"
                    :rules="serviceFeeRules"
                  ></v-text-field>
                </div>
              </div>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title"> Commission:<span style="color:red">*</span></span>
                </div>
                <div style="width: 50%" align="right">
                  <v-text-field
                    label="Commission Fee"
                    solo
                    type="number"
                    v-model="commission"
                    :rules="commissionFeeRules"
                  ></v-text-field>
                </div>
              </div>

              <!-- <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title"> Description:</span>
                </div>
                <div style="width: 50%" align="right">
                  <v-textarea
                    label="Description"
                    solo
                    v-model="short_description"
                  ></v-textarea>
                </div>
              </div>

                <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title"> Description (Burmese)</span>
                </div>
                <div style="width: 50%" align="right">
                  <v-textarea
                    label="Description"
                    solo
                    v-model="short_description_mm"
                  ></v-textarea>
                </div>
              </div> -->

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title"> KBZ Pay Number:<span style="color:red">*</span></span>
                </div>
                <div style="width: 50%" align="right">
                  <v-text-field
                    label="KBZPay Number"
                    solo
                    v-model="kbzPayNumber"
                  ></v-text-field>
                </div>
              </div>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title"> KBZ Bank Account Number:</span>
                </div>
                <div style="width: 50%" align="right">
                  <v-text-field
                    label="Account Number"
                    solo
                    v-model="account_number"
                  ></v-text-field>
                </div>
              </div>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title">Payment Settlement:<span style="color:red">*</span></span>
                </div>
                <div style="width: 50%" align="right">
                  <v-select
                    label="Payment mode"
                    :items = "mode_of_settlement_list"
                    solo
                    v-model="selected_payment"
                  ></v-select>
                </div>
              </div>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 600" align="left">
                  <span class="css-title"> Suggested Medicines to keep in stock:</span>
                </div>
                <div style="width: 50%" align="right">
                  <v-text-field
                    label="Suggested Medicines"
                    solo
                    v-model="suggestedMedicineStock"
                  ></v-text-field>
                </div>
              </div>

              <div>
                <v-btn style="width:50%;" color="primary" :disabled = "checkButton()" @click="editDoctors">Apply Changes</v-btn>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</v-app>

</div>
</template>


<script>

import axios from "axios";
import NavBar from '../../../views/navbar.vue';
import SideBar from "../../../views/sideBar.vue";

export default {
  name: 'EditDoctor',
  data() {
  	return {
      selected_doctor: "",
  		doctor_name: "",
      old_name: "",
      doctor_name_mm: "",
      doctor_email: "",
      old_email: "",
      currentUser: "",
      currentUserName: "",
      user_id: "",
      phone_number: "",
      pageName: "createDoctor",
      specialist_array: [],
      selected_specialization: "",
      short_description: "",
      short_description_mm: "",
      doctor_id: "",
      gender: ["Male", "Female", "Other"],
      academicTitles : ["Professor and Head" , "Professor" , "Associate Professor" , "Senior Consultant" , "Consultant" , "Specialist" , "GP"],
      doctor_gender: "",
      //service_year: "",
      account_number: "",
      kbzPayNumber: "",
      available_status_list: ["UNAVAILABLE","AVAILABLE"],
      availability_status: "",
      service_fee: "",
      commission: "",
      experience: "",
      profile_pic: "",
      doctor_profile_picture:"",
      mode_of_settlement_list: ["Monthly","Bi-weekly"],
      selected_payment: "",
      experienceRules : [v => v > 0 || "Experience cannot be negative"],
      serviceFeeRules : [v => v > 0 || "Service Fee cannot be negative"],
      titleRules : [ v => !!v || 'Title is required'],
      commissionFeeRules : [v => v > 0 || "Commission Fee cannot be negative"],
      serviceYearRules : [v => v > 0 || "Service Year cannot be negative"],
      mobileNumberRules: [
                        v => !!v || 'Mobile Number is required',
                        v => (v.length > 7 && v.length <= 11) || 'Mobile Number should be between 7 to 11 digits',
                        v => v > 0 || 'Mobile number cannot be negative',
                        ],
      altMobileNumberRules : [
                        v => (!v || v && v.length == 0 || v > 0) || 'Mobile number cannot be negative',
                        v => (!v || v && v.length == 0 || (v.length > 7 && v.length <= 11)) || 'Mobile Number should be between 7 to 11 digits',
                        ],
       accountNumberRules: [
                        v => !!v || 'Account Number is required',
                        v => (v && v.length == 17) || 'Account Number should be 17 digits',
                        v => v > 0 || 'Account number cannot be negative',
                        ],
      specializedSubjectRules : [
                        v => (!v || (v.length > 0 && (/^[^,]+(?:,[^,]*){0,2}$/g.test(v)))) || 'Enter 3 specialized Subjects at most'
                        ],
      emailRules: [
                    v => !!v || 'Email is required',
                    v => (/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/g.test(v)) || 'Enter valid Email'
                    ],
      doctorJoinDate:"",
      doctorDOB:"",
      doctorAltPhoneNumber:"",
      residentialState:"",
      degree:"",
      affiliatedHospital:"",
      acadaemicTitle:"",
      committedSchedule:"",
      consultationMinutes:"",
      breakTimeMinutes:"",
      suggestedMedicineStock:"",
      menu:"",
      menu_obj:"",
      specializedSubject:"",
      isButtonClicked: false,
    }
  },
  mounted() {
    this.currentUser = this.$cookies.get("token");
    if(!this.currentUser){
      this.$router.push({
        name: "AdminLogin",
      });
    }
    else{
      var getAccessData = {
        token : this.currentUser,
        typeOfUser : "ADMIN",
        adminType : "ADMIN"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL+"/getAdmin",getAccessData)
      .then((getAdminResponse) => {
        this.currentUserName = getAdminResponse.data.data.admin_name;
        this.user_id = getAdminResponse.data.data._id;
        var params = this.$route.params;
        var doctor_id = params['doctor_id'];
        this.selected_doctor = doctor_id;
        this.FillTable();
      })
      .catch((getAdminError) => {
        if(getAdminError.response.status == 401){
          window.alert("Sorry !! You are unauthorized !!");
          this.$router.push({
            name : "AdminLogin"
          });
        }
        else{
          window.alert("Something went wrong...");
        }
      })
    }
  },
  components: {
    NavBar,
    SideBar
  },
  methods: {
    checkButton(){
      return (!(this.doctor_name.trim() != '' && this.doctor_name_mm.trim() != '' && this.selected_specialization && this.phone_number.length > 7  && this.phone_number.length <=11 &&
       (!this.specializedSubject || (this.specializedSubject.length > 0 && (/^[^,]+(?:,[^,]*){0,2}$/g.test(this.specializedSubject)))) && this.degree &&
       (!this.doctorAltPhoneNumber || (this.doctorAltPhoneNumber.length == 0 || this.doctorAltPhoneNumber > 0)) && (!this.doctorAltPhoneNumber || this.doctorAltPhoneNumber.length == 0 ||
       (this.doctorAltPhoneNumber.length > 7 && this.doctorAltPhoneNumber.length <= 11)) &&this.doctor_email.trim() != '' && this.service_fee > 0 && this.commission > 0 && this.experience > 0 &&
        this.doctor_gender && this.doctorDOB && this.residentialState.trim() != '' && this.doctor_id != '' && this.acadaemicTitle.trim() != '' && this.committedSchedule
        && this.consultationMinutes && this.breakTimeMinutes && this.kbzPayNumber && this.selected_payment) || this.isButtonClicked)
    },
    getBase64(e){
      // get the files
      let files = e.target.files;
      // Process each file
      var allFiles = [];
      let file = files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // Make a fileInfo Object
        let fileInfo = {
          name: file.name,
          type: file.type,
          size: Math.round(file.size / 1000)+' kB',
          base64: reader.result,
          file: file
        };
        this.profile_pic = fileInfo.base64;
      }
    },
    redirectToViewAllDoctors()
    {
     this.$router.push({
       name: "ViewAllDoctors",
     });
   },
   gotoLoginPage() {
    var deleteData = {
     idToken : this.currentUser
   }
   axios.post(process.env.VUE_APP_BACKEND_URL + "/deleteAdminToken",deleteData)
   .then((deleteResponse) => {
     this.$cookies.remove("token");
   })
   .catch((error) => {
   })
   localStorage.clear();
   this.$router.push({
     name: "AdminLogin",
   });
 },
 editDoctors()
 {
  //if((!doctorId) || (!doctorName) || (!specialist) || (!doctorPhonenumber) || (!doctorEmail) || (!createdBy) || (!serviceFee) || (!kbzBankAccountNumber))
  this.isButtonClicked = true;
  var doctor_edit_body = {
    "doctorId": this.selected_doctor,
    "doctorName":this.doctor_name.trim(),
    "doctorName_mm" :this.doctor_name_mm.trim(),
    "gender": this.doctor_gender,
    "samaId": this.doctor_id,
    "specialist": [{"_id":this.selected_specialization._id, "specialization_name": this.selected_specialization.specialization_name}],
    "specSubs": this.specializedSubject === null? '': this.specializedSubject,
    "description": this.degree.trim(),
    "description_mm": this.degree.trim(),
    "doctorPhonenumber": this.phone_number[0] == 0 ? this.phone_number : "0" + this.phone_number,
    "doctorEmail": this.doctor_email.trim(),
    "updatedBy":this.user_id,
    "service_fee": this.service_fee,
    "commission": this.commission,
    "kbzBankAccountNumber": !this.account_number ? '' : this.account_number,
    "kbzPayNumber": this.kbzPayNumber,
    "availableStatus" : "AVAILABLE",
    //"serviceYear": this.service_year,
    "experience": this.experience,
    "base64ProfilePic": !this.profile_pic ? '' : this.profile_pic,
    "token" : this.currentUser,
    "typeOfUser" : "ADMIN",
    "modeOfSettlement" : this.selected_payment,
    "state":this.residentialState,
    "hospital": !this.affiliatedHospital ? '' : this.affiliatedHospital,
    "altNumber": !this.doctorAltPhoneNumber ? '' : this.doctorAltPhoneNumber ,
    "title": this.acadaemicTitle,
    "schedule": this.committedSchedule,
    "meetingMinutes": this.consultationMinutes,
    "breakMinutes": this.breakTimeMinutes,
    "suggestedMeds": !this.suggestedMedicineStock ? '' : this.suggestedMedicineStock,
    "joinDate": this.doctorJoinDate,
    "dateOfBirth":this.doctorDOB,
    "emailChanged": this.doctor_email.trim() != this.old_email ? true : false,
    "nameChanged": this.doctor_name.trim() != this.old_name ? true : false,
    token : this.currentUser,
    typeOfUser : "ADMIN",
  }

  // (!doctorId) || (!samaId) || (!specialist) || (!doctorName) || (!doctorNameMM) || (!doctorPhonenumber) || (!doctorEmail) || (!updatedBy)  || (!serviceFee) || (!modeOfSettlement)
  //        || (!joinDate) ||  (!dateOfBirth) || (!gender) || (!state) || (!hospital) || (!description) || (!doctorDescriptionMM) || (!experience) || (!title) ||
  //        (!kbzPayNumber) || (!meetingMinutes) ||  (!breakMinutes) || (!schedule) || (!commission))
  axios.post(process.env.VUE_APP_BACKEND_URL+"/updateDoctorDetails",doctor_edit_body)
  .then((editDoctorsResponse) =>{
    alert("Doctor edited");

    this.redirectToViewAllDoctors()
  })
  .catch((editDoctorsError) => {
    console.log(editDoctorsError);
    this.isButtonClicked = false;
    if(editDoctorsError.response.status == 401){
      window.alert("Sorry !! You are unauthorized !!");
      this.$router.push({
        name : "AdminLogin"
      });
    }
    else if(editDoctorsError.response.status == 403){
      window.alert("Please provide all the required fields");
    }
    else if(editDoctorsError.response.status == 305)
    {
      window.alert("Email is already associated with another doctor");
    }
    else{
      window.alert("Something went wrong...");
    }
  });
},
FillTable()
{
  var doctor_body = {
    "doctorId": this.selected_doctor,
    "token" : this.currentUser,
    "typeOfUser" : "ADMIN"
  };
  axios.post(process.env.VUE_APP_BACKEND_URL+"/getDoctorFromId",doctor_body)
  .then((FillTableResponse) => {
    var response_data = FillTableResponse.data.data;
    this.doctor_name = response_data.doctor_name;
    this.doctor_gender = response_data.gender;
    this.selected_specialization = response_data.specialist[0]; //item-text "spec_name" as "name" item-value
    this.selected_specialization.specialization_name = this.selected_specialization.name ? this.selected_specialization.name :  this.selected_specialization.specialization_name;
    this.specializedSubject = response_data.spec_subjects
    //this.service_year = response_data.service_year;
    this.service_fee = response_data.service_fee;
    this.commission = response_data.commission;
    this.account_number  = response_data.kbz_bank_account_number;
    this.kbzPayNumber = response_data.kbzpay_number;
    this.doctor_id = response_data.doctor_id;
    this.doctor_email = response_data.doctor_email;
    this.phone_number = response_data.doctor_phonenumber;
    this.experience = response_data.experience;
    //this.service_year = response_data.service_year;
    this.short_description = response_data.description;
    this.selected_payment = response_data.mode_of_settlement;
    this.doctorJoinDate = response_data.join_date;
    this.doctorDOB = response_data.date_of_birth;
    this.doctorAltPhoneNumber = response_data.doctor_alt_phonenumber;
    this.residentialState = response_data.doctor_res_state;
    this.committedSchedule = response_data.committed_schedule;
    this.acadaemicTitle = response_data.doctor_title;
    this.affiliatedHospital = response_data.affiliated_hospital;
    this.consultationMinutes = response_data.meeting_minutes;
    this.breakTimeMinutes = response_data.break_minutes;
    this.suggestedMedicineStock = response_data.suggested_default_med;
    this.degree = response_data.description;
    this.old_email = this.doctor_email;
    this.old_name = this.doctor_name;
    this.doctor_name_mm = response_data.doctor_name_mm;
    this.short_description_mm = response_data.description_mm;
    if(response_data.doctor_profile_picture == "" || !response_data.doctor_profile_picture){

     if(response_data.gender == "Female"){
        this.doctor_profile_picture = "https://d3vsnl8idgwrlw.cloudfront.net/female.svg"
      }
      else{
        this.doctor_profile_picture = "https://d3vsnl8idgwrlw.cloudfront.net/male.svg"
      }
    }
    else{
      this.doctor_profile_picture = response_data.doctor_profile_picture;
    }

    var getAccessData = {
      token: this.currentUser,
      typeOfUser: "ADMIN",
    };
    axios.post(process.env.VUE_APP_BACKEND_URL+"/readAllSpecializations",getAccessData)
    .then((readSpecResponse) => {
      this.specialist_array = readSpecResponse.data.data;
      if(this.selected_specialization.specialization_name === undefined)
      {
        this.selected_specialization = this.specialist_array.find(o => o._id === this.selected_specialization._id);
      }
    })
    .catch((readSpecError) => {
      window.alert(readSpecError);
    });
  })
  .catch((FillTableError) => {
    console.log(FillTableError);
    if(FillTableError.response.status == 401){
      window.alert("Sorry !! You are unauthorized !!");
      this.$router.push({
        name : "AdminLogin"
      });
    }
    else{
      window.alert("Something went wrong...");
    }
  });
}
}
}
</script>

<style>

td,
th {
	padding:10px;
}
#facebook{
  display: none;
}
</style>
